import { css } from '@emotion/css';
import Link from 'gatsby-link';
import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import profileImage from '../assets/images/profile.jpg';
import Employment from '../components/Employment';
import Layout from '../components/Layout';
import { textStyles } from '../styles/utils';
import { breakpoints } from '../styles/variables';

const containerStyle = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: row;
  }
`;

const profileImageStyle = css`
  border-radius: 50%;
  margin: 0 auto 2rem;
  max-width: 10rem;
  width: 100%;

  @media (min-width: ${breakpoints.mobile}) {
    margin-right: 2rem;
    max-width: 14rem;
  }
`;

const jobs = [
  {
    role: 'Senior Software Engineer - Hive IT',
    dates: '2016 - Present',
    description: `Agency work with private/public sector clients on a variety of projects of different sizes.`,
    technologies:
      'Java, Kotlin, TypeScript, JavaScript, C#, PHP, Spring, React, React Native, Vue, .NET, Laravel, Kubernetes, Ansible, Docker, AWS, Google Cloud, Azure',
  },
  {
    role: 'Freelance Developer',
    dates: '2014 - Present',
    description: `Work on small/medium sized projects for various clients in my free time. Mostly full-stack 
    development with previous responsibilities including leading a small team and maintaining project 
    infrastructure.`,
    technologies:
      'PHP, JavaScript, Laravel, Vue, AngularJS, Elasticsearch, Redis, Ansible, React, React Native, Vue',
  },
  {
    role: 'Developer - Databowl',
    dates: '2015 - 2016',
    description: `Worked on the company flagship SaaS product. Mostly full-stack development alongside 
    a small team of developers.`,
    technologies:
      'PHP, JavaScript, Zend Framework 2, Laravel, AngularJS, Elasticsearch, Redis, RabbitMQ',
  },
  {
    role: 'Developer - Switchstance IT',
    dates: '2014 - 2015',
    description:
      'Agency work with private/public sector clients on a variety of small projects.',
    technologies: 'PHP, JavaScript, CakePHP',
  },
];

const AboutPage = (): ReactElement => (
  <Layout>
    <Helmet title="About Me" />

    <h1 className={textStyles.center}>About Me</h1>

    <div className={containerStyle}>
      <img
        src={profileImage}
        alt="Nicholas Tsim"
        className={profileImageStyle}
      />

      <div>
        <p>
          Hi there, I'm Nicholas Tsim, a full stack senior software developer
          with over 5 years of industry experience based in Sheffield, UK.
        </p>

        <p>
          I'm able to comfortably work with frontend, backend and DevOps stacks
          and typically work with Kotlin/Java (Spring) and TypeScript/JavaScript
          (React, Vue, Node). More recently this has expanded into C# (.NET) and
          dabbling with Rust.
        </p>

        <p>
          I'm absolutely passionate about all things code and love keeping
          up-to-date with industry trends. When I have time, I also try and
          write about my thoughts/learnings in my <Link to="/">blog</Link>.
        </p>

        <p>
          I also regularly work as a freelance developer and am open to taking
          on new projects.{' '}
          <a href="mailto:nicholastsim@gmail.com">Get in touch!</a>
        </p>
      </div>
    </div>

    <div>
      <h2>Work History</h2>

      {jobs.map((job) => (
        <Employment {...job} key={job.role} />
      ))}
    </div>
  </Layout>
);

export default AboutPage;
