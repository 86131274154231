import { css } from '@emotion/css';
import { lighten } from 'polished';
import React, { ReactElement } from 'react';
import { colours } from '../styles/variables';

const employmentStyle = css`
  border-left: 4px solid ${lighten(0.75, colours.tertiary)};
  margin-bottom: 0;
  margin-left: 2rem;
  padding-bottom: 3rem;
  padding-left: 2rem;
  position: relative;

  &::before {
    background: ${colours.primary};
    border: 3px solid ${colours.primary};
    border-radius: 10px;
    content: '';
    display: block;
    height: 20px;
    left: -12px;
    position: absolute;
    top: 1.5rem;
    width: 20px;
  }
`;

const descriptionStyle = css`
  margin-bottom: 1rem;
`;

const technologiesStyle = css`
  color: ${colours.grey};
  font-size: 14px;
  font-style: italic;
  margin-bottom: 0;
`;

interface Props {
  role: string;
  dates: string;
  description: string;
  technologies: string;
}

const Employment = ({
  role,
  dates,
  description,
  technologies,
}: Props): ReactElement => (
  <div className={employmentStyle}>
    <small>{dates}</small>

    <h3>{role}</h3>

    <p className={descriptionStyle}>{description}</p>

    <p className={technologiesStyle}>{technologies}</p>
  </div>
);

export default Employment;
