import { css } from '@emotion/css';

// eslint-disable-next-line import/prefer-default-export
export const textStyles = {
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `,
};
